.div-inline {
	display: inline-block;
}
.inter-medium {
	font-family : 'Inter', sans-serif;
	font-weight : 700;
	font-size : 30px;
	color : #2B2F34;
}

.inter-regular {
	font-family : 'Inter', sans-serif;
	font-weight : 400;
	font-size : 25px;
	color : #808285;
}
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;900&display=swap'); */
.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#dogs {
    margin-top : 30px;
}
#logoPets {
    margin-bottom : 30px;
}
.inter-medium {
    font-family : 'Inter', sans-serif;
    font-weight : 700;
}
.inter-regular {
    font-family : 'Inter', sans-serif;
    font-weight : 400;
    font-size : 40px;
    color : #808285;
}
.inter-black {
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    font-size: 72px;
    color : #2B2F34;
}
